/***************************************************************
 * FileName: Cell.js
 * Descripsion: DUNGEONS Client
 * Copyright: akiohada
 * Original Author: Akio Hada
 * Date Created: 2019-08-01
***************************************************************/

//Packages
import React from 'react'

//Custom Components

class Cell extends React.Component {
  _handleOnPress = () => {
    this.props.onBtnPress();
  }
  render() {
    const bgcolors = {
      backgroundColor: this.props.bgcolor,
      width: 30,
      height: 30,
      border: "1px solid gray",
      display: 'inline-block',
      margin: '0',
    }
    return (
      <div>
        <div
          onClick={() => this._handleOnPress()}
          style={bgcolors}
        />
        <p>{this.props.bgcolor}</p>
      </div>
    )
  }
}

export default Cell
