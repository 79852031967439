/***************************************************************
 * FileName: GridRow.js
 * Descripsion: DUNGEONS Client
 * Copyright: akiohada
 * Original Author: Akio Hada
 * Date Created: 2019-08-01
***************************************************************/

//Packages
import React from 'react'

//Custom Components
import Cell from './Cell'

const rowStyle = {
  margin: '0',
  marginBottom: '-8px',
  padding: '0',
}

class GridRow extends React.Component {
  _handleOnPress = () => {
    this.props.onBtnPress();
  }
  render() {
    return (
      <div style={rowStyle}>
        <Cell
          onBtnPress={() => this._handleOnPress()}
          bgcolor={this.props.bgcolor}
        />
      </div>
    )
  }
}

export default GridRow
