/***************************************************************
 * FileName: index.js
 * Descripsion: DUNGEONS Client
 * Copyright: akiohada
 * Original Author: Akio Hada
 * Date Created: 2019-08-01
***************************************************************/

//Packages
import React from 'react'
import Helmet from 'react-helmet'

//Cunstom Components
import GridRow from './components/GridRow'

const bgcolor = [`white`,`black`]

class DungeonsTest extends React.Component {
  constructor(props) {
    //Cliche
    super(props);
    //Declare States
    this.state = {
      grid: [['x0y0','x1y0']],
      bgc: 'white',
    };
    //thisのバインド
    this._handleEvent = this._handleEvent.bind(this);
  }

  _handleEvent = () => {
    this.setState({
      bgc: 'green',
    });
  }
  // {this.state.grid.map(x => <GridRow id={x} onBtnPress={this._handleEvent} bgcolor={this.state.bgc} />)}

  render() {
    return (
      <div>
        <Helmet
          title="DUNGEONS"
          meta={[
            { name: 'description', content: 'Sample' },
            { name: 'keywords', content: 'sample, something' },
          ]}
        >
        </Helmet>

          <div>
            <GridRow onBtnPress={this._handleEvent} bgcolor={this.state.bgc} />
          </div>
      </div>
    )
  }
}

export default DungeonsTest
